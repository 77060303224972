import { useRef, useState } from "react";
import {
	Box,
	Button,
	Modal,
	ModalDialog,
	DialogTitle,
	Typography,
	DialogContent,
	DialogActions,
	Checkbox,
	CircularProgress,
	Alert,
	List,
	ListItem
} from "@mui/joy";
import { useQuery } from "@tanstack/react-query";

import {
	LOCALES,
	DEFAULT_LOCALE,
	LOCALES_SHORT
} from "../../../constants/defaults";
import { useLocale } from "../../../hooks/useLocale";
import { useGetValuesByGroup } from "../../../lib/values";
import { useTranslateText } from "../../../lib/ai";

import toast from "react-hot-toast";

export default function ModalSuggestTranslation({
	openModal,
	setOpenModal,
	onSubmit,
	values,
	trip
}) {
	const [locale] = useLocale();
	const [langSelected, setLangSelected] = useState([]);
	const [loading, setLoading] = useState(false);

	const { data: glossary } = useQuery([`value`], async () => {
		try {
			return await useGetValuesByGroup("TRANSLATE_GLOSSARIES");
		} catch (e) {
			return true;
		}
	});

	const handleSubmit = async () => {
		setLoading(true);

		try {
			const toTranslate = values.i18n[DEFAULT_LOCALE];
			toTranslate.temp_slug = toTranslate.link
				.replace("https://www.explore-share.com/trip/", "")
				.replaceAll("-", " ")
				.replaceAll("/", " ");

			let newI18nTranslated = {};
			for await (const toLanguage of langSelected) {
				const translate = await useTranslateText({
					template: `
              I am an expert translator. My goal is to provide the best translation possible. I will translate your text from ${LOCALES[DEFAULT_LOCALE]} to ${LOCALES[toLanguage]}.
              In the JSON, I will find words that are between curly braces like {{ location }}, this words are variables and I don't have to be translate them, they must be kept them in the original language.
              I have a glossary with the words and their translations also indication for get a best translation, and I will use it to translate the text.
            `,
					query: `
              Please, translate this JSON to ${LOCALES[toLanguage]}, translate only the values and not the keys. Respond with the same json but translated. And remember, you need to use the glossary for the translation.
            `,
					data: JSON.stringify(toTranslate),
					glossary: glossary.filter(
						(g) =>
							g.key ===
							`${LOCALES_SHORT[DEFAULT_LOCALE]}_${LOCALES_SHORT[toLanguage]}`
					)[0]?.value
				});

				const { response: json } = await translate.json();

				const parsedResponse = JSON.parse(
					json.replace("```json\n", "").replace("\n```", "")
				);

				newI18nTranslated = {
					...newI18nTranslated,
					[toLanguage]: parsedResponse
				};
			}

			const finalData = {
				...trip,
				i18n: {
					...trip.i18n,
					...newI18nTranslated
				},
				"sync_status.i18n": "WAITING_PUBLISHED"
			};

			onSubmit(trip._id, finalData);
		} catch (e) {
			toast.error("This didn't work.");
		}

		setOpenModal(false);
		setLoading(false);
	};

	if (!locale) return null;

	return (
		<Modal open={openModal}>
			<ModalDialog sx={{ width: "500px" }}>
				{loading ? (
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							justifyContent: "center"
						}}>
						<Typography level="body-md" startDecorator={<CircularProgress />}>
							This process could take a few minutes.
						</Typography>
					</Box>
				) : (
					<Box
						sx={{
							borderRadius: "8px",
							display: "flex",
							flexDirection: "column",
							gap: "20px"
						}}>
						<DialogTitle>
							<Typography level="h4">
								There are other languages available to translate, do you want to
								translate them?
							</Typography>
						</DialogTitle>
						<DialogContent>
							<List size="sm">
								{Object.keys(LOCALES)
									.filter((l) => l !== DEFAULT_LOCALE)
									.map((l) => (
										<ListItem>
											<Checkbox
												label={LOCALES[l]}
												value={l}
												onChange={(event) => {
													setLangSelected(
														!event.target.checked &&
															langSelected.includes(event.target.value)
															? langSelected.filter(
																	(i) => i !== event.target.value
															  )
															: [...langSelected, event.target.value]
													);
												}}
											/>
										</ListItem>
									))}
							</List>
							<Alert color="warning">
								once applied consider read proof to validate the correct
								translation
							</Alert>
						</DialogContent>
						<DialogActions>
							<Box sx={{ display: "flex", gap: "10px", justifyContent: "end" }}>
								<>
									<Button
										loading={loading}
										variant="plain"
										color="neutral"
										onClick={() => {
											setOpenModal(false);
										}}>
										Cancel
									</Button>

									{langSelected.length ? (
										<Button
											loading={loading}
											onClick={() => {
												handleSubmit();
											}}>
											Translate and Save
										</Button>
									) : (
										<Button
											loading={loading}
											onClick={() => {
												onSubmit(trip._id, values);
												setOpenModal(false);
											}}>
											Save
										</Button>
									)}
								</>
							</Box>
						</DialogActions>
					</Box>
				)}
			</ModalDialog>
		</Modal>
	);
}
